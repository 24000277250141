import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import Anchor from '../../atoms/Anchor';
import Heading from '../../atoms/Heading';
import Para from '../../atoms/Para';
import { locationLabels } from '../../../constants/labels/en';
import HoursOfOperation from '../../molecules/HoursOfOperation';
import { imageRenditions } from './config';
import Image from '../../atoms/Image';
import { getAddressURL, getCompleteAddress, parseWorkingHours, convertPhoneFormat } from '../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../constants/globalConstants';
import styles from './GridItem.style';
var GridItemBlock = styled.section.withConfig({
  componentId: "sc-1f49pln-0"
})(["", ";"], styles);
var GridItem = function GridItem(_ref) {
  var className = _ref.className,
    item = _ref.item,
    gridColumns = _ref.gridColumns,
    cardClassName = _ref.cardClassName,
    dataAnalyticsValue = _ref.dataAnalyticsValue,
    dataAnalyticsTypeCard = _ref.dataAnalyticsTypeCard;
  var _useState = useState(false),
    displayHours = _useState[0],
    setDisplayHours = _useState[1];
  var name = item.name,
    address = item.address,
    mainPhone = item.mainPhone,
    c_websitePhone = item.c_websitePhone,
    additionalHoursText = item.additionalHoursText,
    c_extendedAdditionalHoursText = item.c_extendedAdditionalHoursText,
    meta = item.meta,
    websiteUrl = item.websiteUrl,
    c_googleMyBusinessCID = item.c_googleMyBusinessCID,
    c_locationMenuImage = item.c_locationMenuImage,
    hours = item.hours,
    c_extendedHours = item.c_extendedHours,
    c_webName = item.c_webName;
  var GMBUrl = c_googleMyBusinessCID && "https://maps.google.com/maps?cid=".concat(c_googleMyBusinessCID);
  var _parseWorkingHours = parseWorkingHours(hours, c_extendedHours),
    extendedHours = _parseWorkingHours.extendedHours,
    perDayHoursDetailArray = _parseWorkingHours.perDayHoursDetailArray,
    extendedHoursDetailArray = _parseWorkingHours.extendedHoursDetailArray,
    nameOfDay = _parseWorkingHours.nameOfDay;
  var hoursOfOperationObject = {
    ariaLabel: c_webName ? c_webName : name,
    headingText: c_webName ? c_webName : name,
    mainHoursText: locationLabels.mainhours,
    extraHoursText: locationLabels.emergencyhours,
    extendedHours: extendedHours,
    perDayHoursDetailArray: perDayHoursDetailArray,
    extendedHoursDetailArray: extendedHoursDetailArray,
    nameOfDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    hoursTextLine: additionalHoursText,
    extendedHoursTextLine: c_extendedAdditionalHoursText
  };
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var getHoursTextAndColor = function getHoursTextAndColor() {
    var now = new Date().toLocaleDateString('en-US', {
      weekday: 'short'
    });
    var dayIndex = nameOfDay.indexOf(now);
    if (dayIndex != -1 && perDayHoursDetailArray && perDayHoursDetailArray.length == 7) {
      var dayTimings = perDayHoursDetailArray[dayIndex].split(' - ');
      if (dayTimings.length > 1) {
        return ["Open today until ".concat(dayTimings[dayTimings.length - 1]), ''];
      } else if (dayTimings.length > 0) {
        var text = dayTimings[dayTimings.length - 1];
        if (text == 'Closed') {
          var dayTimes;
          for (var i = 1; i < 7; i++) {
            dayIndex = dayIndex + 1;
            if (dayIndex == 7) {
              dayIndex = 0;
            }
            dayTimes = perDayHoursDetailArray[dayIndex].split(' - ');
            if (dayTimes.length > 1) {
              return ["Closed, Opens at ".concat(dayTimes[0], " ").concat(nameOfDay[dayIndex]), 'closed'];
            }
            if (dayTimes.length > 0 && dayTimes[0] == 'Open 24 hrs') {
              return ["Closed, Opens on ".concat(nameOfDay[dayIndex], " 24 hours"), 'closed'];
            }
          }
        }
        if (text == 'Open 24 hrs') {
          return [dayTimings[dayTimings.length - 1], ''];
        }
      }
    }
    return ['', ''];
  };
  var hoursText = getHoursTextAndColor();
  var imageConfig = imageRenditions(gridColumns);
  return __jsx(GridItemBlock, {
    className: "nva-multi-location-grid-item ".concat(className)
  }, __jsx("div", {
    className: "nva-multi-location-grid-item__card flip-card".concat(displayHours ? ' turn' : '')
  }, __jsx("div", {
    className: "flip-card-inner"
  }, __jsx("div", {
    className: "flip-card-front"
  }, __jsx("div", {
    key: meta && meta.id,
    className: cardClassName
  }, c_locationMenuImage && c_locationMenuImage.thumbnails && c_locationMenuImage.thumbnails[0].url && __jsx("div", {
    className: "nva-multi-location-grid-item__card-image grid-card-image"
  }, __jsx(Image, {
    key: name,
    srcSetSettingImg: imageConfig,
    src: c_locationMenuImage.thumbnails[0].url,
    alt: c_locationMenuImage.alternateText || name,
    title: c_locationMenuImage.alternateText || name
  })), __jsx("div", {
    className: "nva-multi-location-grid-item__card-body grid-card-body"
  }, websiteUrl && __jsx("div", {
    className: "nva-multi-location-grid-item__card-location-name grid-card-location-name"
  }, __jsx(Heading, {
    HeadingType: "h3"
  }, __jsx(Anchor, {
    title: c_webName ? c_webName : name,
    to: websiteUrl.url
  }, c_webName ? c_webName : name))), __jsx("div", {
    className: "nva-multi-location-grid-item__hours-helper hours-helper ".concat(hoursText[1]),
    onClick: function onClick(event) {
      event.preventDefault();
      setDisplayHours(!displayHours);
    }
  }, __jsx(Para, null, __jsx(Anchor, {
    to: "#!",
    title: "".concat(hoursText[0] ? hoursText[0] : 'hours of operations'),
    className: "nva-multi-location-grid-item__hours-of-operations"
  }, hoursText[0], " >"))), addressString && __jsx("div", {
    className: "nva-multi-location-grid-item__card-address grid-card-address"
  }, __jsx(Para, null, __jsx(Anchor, {
    title: addressString,
    to: GMBUrl || addressStringURL,
    ctaBehavior: linkTargetWindow.newWindow,
    "data-analytics-type": dataAnalyticsTypeCard || '',
    "data-analytics-value": dataAnalyticsValue || ''
  }, addressString))), c_websitePhone || mainPhone ? __jsx("div", {
    className: "nva-multi-location-grid-item__card-phone-number grid-card-phone-number"
  }, __jsx(Para, null, __jsx(Anchor, {
    title: convertPhoneFormat(c_websitePhone || mainPhone),
    href: "tel:".concat(convertPhoneFormat(c_websitePhone || mainPhone)),
    to: "tel:".concat(convertPhoneFormat(c_websitePhone || mainPhone))
  }, "T: ".concat(convertPhoneFormat(c_websitePhone || mainPhone))))) : null)), websiteUrl && websiteUrl.url && __jsx("div", {
    className: 'nva-multi-location-grid-item__grid-visit-site visit-site'
  }, __jsx(Para, null, __jsx(Anchor, {
    title: "".concat(locationLabels.visitsite),
    styleType: "primary-anchor",
    to: websiteUrl.url
  }, locationLabels.visitsite, " >")))), __jsx("div", {
    className: "nva-multi-location-grid-item__card-back flip-card-back"
  }, __jsx("div", {
    className: "repeat-svg",
    onClick: function onClick() {
      return setDisplayHours(!displayHours);
    }
  }, "\xD7"), hoursOfOperationObject && __jsx(HoursOfOperation, _extends({}, hoursOfOperationObject, {
    className: "nva-multi-location-grid-item__hours-of-operation-panel panel-section"
  }))))));
};
GridItem.defaultProps = {
  className: '',
  item: {},
  infoRowClassName: '',
  infoRowKeyClassName: '',
  infoRowValueClassName: '',
  headingClassName: '',
  map: false,
  listInfoClassName: '',
  mobileMoreInfoClassName: ''
};
export default styled(GridItem).withConfig({
  componentId: "sc-1f49pln-1"
})(["", ";"], styles);