import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import textures from '../../../themes/textures';
import fontColor from '../../../constants/globalConstants';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling, undoSettingOfNonCtaAnchorStyling, replacePrimaryAnchorWithLightPrimaryAnchor } from '../../../utils/utilityFunction';
var backgroundStyles = '';
var MultiLocationGridStyles = css(["", ";background-size:cover;padding:20px;.multi-location-rich-text{display:inline;text-transform:none;height:100%;width:100%;}.addressBox{font-size:110%;padding:10px 0;}.multi-location-header{text-align:center;margin-bottom:3em;&-btn{display:inline-block;margin-top:10px;width:auto;height:auto;}.sg-btn-cpnt.primary-anchor{", "}h2,h1,h3,h4,h5,p,ul,ol,.heading_four{color:", ";}.multi-location-rich-text{ul{li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}a{text-align:center;", "}}h2{text-align:center;display:block;margin:0 auto 20px;}.heading_four{margin:0 auto 20px;}p{margin:0 auto 20px;}}.multi-location-grid{display:grid;width:100%;grid-template-columns:repeat( ", ",minmax(200px,1fr) );grid-gap:35px 10px;justify-content:", ";align-items:normal;.flip-card{", "}@media (max-width:", "){display:block;order:2;width:95%;margin:5px auto;margin-top:15px;}@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){display:-ms-flexbox;flex-wrap:wrap;flex:1 1 auto;}@media (max-width:", "){grid-template-columns:repeat(2,minmax(200px,1fr));}}.multi-location-view-more{margin:30px auto 0;max-width:400px;a:not(.sg-btn-cpnt){text-align:center;}@media (max-width:", "){order:1;max-width:none;width:95%;margin:10px auto;text-align:center;a:not(.sg-btn-cpnt){text-align:center;}}}@media (max-width:", "){width:100%;height:auto;margin:0 auto;display:flex;flex-direction:column;padding:0;}"], function (props) {
  if (props.multiLocationMenuContainers) {
    var _props$theme, _props$theme$fiveCard, _props$theme2;
    var background = props.multiLocationMenuContainers.background;
    var backgroundImageURLs = background && background.imageBackground ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : '';
    var overrideId = background ? background.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, background && background.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$fiveCard = _props$theme.fiveCard) === null || _props$theme$fiveCard === void 0 ? void 0 : _props$theme$fiveCard.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$theme3, _props$theme3$button, _props$theme4;
  return (_props$theme3 = props.theme) !== null && _props$theme3 !== void 0 && (_props$theme3$button = _props$theme3.button) !== null && _props$theme3$button !== void 0 && _props$theme3$button.hasAlternatePrimaryAnchor && !isBgColorStyleLight(backgroundStyles) ? replacePrimaryAnchorWithLightPrimaryAnchor((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.button) : "";
}, function (props) {
  var _props$multiLocationM, _props$multiLocationM2, _props$theme5, _props$theme6;
  return ((_props$multiLocationM = props.multiLocationMenuContainers) === null || _props$multiLocationM === void 0 ? void 0 : _props$multiLocationM.fontColor) === fontColor.light || ((_props$multiLocationM2 = props.multiLocationMenuContainers) === null || _props$multiLocationM2 === void 0 ? void 0 : _props$multiLocationM2.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? "var(--sc-promo-banner--text-color-light, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.promoBanner.textColorLight, ")") : "var(--sc-promo-banner--text-color-dark, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.promoBanner.textColorDark, ")");
}, function (props) {
  var _props$multiLocationM3, _props$multiLocationM4, _props$theme7, _props$theme8, _props$theme8$richTex;
  return ((_props$multiLocationM3 = props.multiLocationMenuContainers) === null || _props$multiLocationM3 === void 0 ? void 0 : _props$multiLocationM3.fontColor) === fontColor.light || ((_props$multiLocationM4 = props.multiLocationMenuContainers) === null || _props$multiLocationM4 === void 0 ? void 0 : _props$multiLocationM4.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.promoBanner) : undoSettingOfNonCtaAnchorStyling("var(--sc-rich-text--color-anchor, ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$richTex = _props$theme8.richText) === null || _props$theme8$richTex === void 0 ? void 0 : _props$theme8$richTex.anchorColor, ")"));
}, function (props) {
  return props.gridColumns ? props.gridColumns : 2;
}, function (props) {
  return props.isTwoCols ? 'center' : 'space-evenly';
}, function (props) {
  if (props.multiLocationMenuContainers && !props.multiLocationMenuContainers.background) {
    return "border: 1px solid #ccc;";
  }
}, breakPoints.tabletP, breakPoints.tabletL, breakPoints.tabletL, breakPoints.tabletP);
export default MultiLocationGridStyles;