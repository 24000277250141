import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
export default css(["display:block;position:absolute;left:15px;.bars{width:30px;display:block;span{border-bottom:2px solid var(--sc-header--bar-color,", ");display:block;margin-bottom:6px;&:last-child{margin-bottom:0;}}.center{width:15px;}}.close-button{height:16px;width:16px;path{stroke:", ";}}&.hamburger-open{.bars{color:var(--sc-header--bar-color,", ");span{display:none;}&:after{content:'X';font-family:var(--sc-font-family-icons,", ");font-size:", ";}}}@media (min-width:", "){display:none;}"], function (props) {
  var _props$theme, _props$theme$header;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$header = _props$theme.header) === null || _props$theme$header === void 0 ? void 0 : _props$theme$header.barColor;
}, fontColor.black, function (props) {
  var _props$theme2, _props$theme2$header;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$header = _props$theme2.header) === null || _props$theme2$header === void 0 ? void 0 : _props$theme2$header.barColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontFamilyIcons;
}, function (props) {
  var _props$theme4, _props$theme5, _props$theme5$desktop, _props$theme6;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && _props$theme4.desktop ? "var(--sc-font-size-h2_desktop, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$desktop = _props$theme5.desktop) === null || _props$theme5$desktop === void 0 ? void 0 : _props$theme5$desktop.fontSizeH2, ")") : "var(--sc-font-size-h2, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.fontSizeH2, ")");
}, breakPoints.desktop);