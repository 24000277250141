export var imageRenditions = function imageRenditions(gridColumns) {
  var cardHeight_desktopL, cardHeight_desktop, cardHeight_desktopS, cardHeight_tabletL, cardHeight_tabletP, cardHeight_mobile;
  var imageHeight_desktopL, imageHeight_desktop, imageHeight_tabletL, imageHeight_tabletP, imageHeight_mobile;
  var image_width = '550';
  if (gridColumns == 2) {
    cardHeight_desktopL = cardHeight_desktopS = 450;
    imageHeight_desktopL = imageHeight_desktop = 200;
    image_width = '365';
  }
  if (gridColumns == 3) {
    cardHeight_desktopL = cardHeight_desktopS = 580;
    imageHeight_desktopL = imageHeight_desktop = 230;
  }
  if (gridColumns == 4) {
    cardHeight_desktopL = 630;
    cardHeight_desktopS = 670;
    imageHeight_desktopL = imageHeight_desktop = 260;
  }
  if (gridColumns == 5) {
    cardHeight_desktopL = 700;
    cardHeight_desktopS = 730;
    imageHeight_desktopL = imageHeight_desktop = 280;
  }
  cardHeight_desktop = cardHeight_tabletP = 640;
  imageHeight_tabletL = imageHeight_tabletP = imageHeight_mobile = 300;
  return {
    imageRenditions: {
      desktopL: {
        h: imageHeight_desktopL,
        w: image_width
      },
      desktop: {
        h: imageHeight_desktop,
        w: image_width
      },
      tabletL: {
        h: imageHeight_tabletL,
        w: image_width
      },
      tabletP: {
        h: imageHeight_tabletP,
        w: image_width
      },
      mobile: {
        h: imageHeight_mobile,
        w: image_width
      }
    },
    cardRenditions: {
      desktopL: {
        h: cardHeight_desktopL
      },
      desktopS: {
        h: cardHeight_desktopS
      },
      desktop: {
        h: cardHeight_desktop
      },
      tabletL: {
        h: cardHeight_tabletL
      },
      tabletP: {
        h: cardHeight_tabletP
      },
      mobile: {
        h: cardHeight_mobile
      }
    }
  };
};