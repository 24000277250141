export var emptySrcSetSettingImg = {
  quality: 100,
  imageRenditions: {
    desktopL: {},
    desktop: {},
    tabletL: {},
    tabletP: {},
    mobile: {}
  }
};
export var leftLogoSrcSetSettingImg = {
  quality: 100,
  imageRenditions: {
    // srcset for 1600 w
    desktopL: {
      w: 198,
      h: 117
    },
    // 1366 - 1599
    desktop: {
      w: 198,
      h: 117
    },
    // 1024 - 1365
    tabletL: {
      w: 198,
      h: 117
    },
    // 768 - 1023
    tabletP: {
      w: 144,
      h: 52
    },
    // up to 767
    mobile: {
      w: 144,
      h: 52
    }
  }
};
export var centerSrcSetSettingImg = {
  quality: 100,
  imageRenditions: {
    // srcset for 1600 w
    desktopL: {
      w: 198,
      h: 107
    },
    // 1366 - 1599
    desktop: {
      w: 198,
      h: 107
    },
    // 1024 - 1365
    tabletL: {
      w: 198,
      h: 107
    },
    // 768 - 1023
    tabletP: {
      w: 180,
      h: 52
    },
    // up to 767
    mobile: {
      w: 180,
      h: 52
    }
  }
};
export var centerLogoEquineImageRenditions = {
  quality: 100,
  imageRenditions: {
    desktopL: {
      h: 117,
      w: 260
    },
    desktop: {
      h: 117,
      w: 260
    },
    tabletL: {
      h: 117,
      w: 260
    },
    tabletP: {
      w: 180,
      h: 52
    },
    mobile: {
      w: 180,
      h: 52
    }
  }
};
export var getSrcSetSettingImg = function getSrcSetSettingImg(variation) {
  var isEquine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var isNvaMainSite = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  switch (variation) {
    case 'logo-left':
      return isNvaMainSite ? emptySrcSetSettingImg : leftLogoSrcSetSettingImg;
    case 'logo-center':
      return isEquine ? centerLogoEquineImageRenditions : centerSrcSetSettingImg;
    default:
      return;
  }
};