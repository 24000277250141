var __jsx = React.createElement;
import React from 'react';
import { showPromoTile } from '../utils/utilityFunction';

/** Funtional  HOC to show the promotion tile
 * @param {string} startDate - Start Date of Promotion.
 * @param {string} endDate - End Date of Promotion.
 * returns - Component
 */

export default function HOC(props) {
  var showTile = showPromoTile(props.startDate, props.endDate);
  return showTile && __jsx("div", null, props.children);
}