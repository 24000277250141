import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Close(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 17.15 17.15"
  }, props), __jsx("defs", null, __jsx("style", null, '.prefix__cls-1{fill:none;stroke:#fff;stroke-width:3px}')), __jsx("g", {
    id: "prefix__X",
    transform: "translate(1.061 1.061)"
  }, __jsx("path", {
    id: "prefix__Line_1",
    d: "M0 0h21.254",
    className: "prefix__cls-1",
    "data-name": "Line 1",
    transform: "rotate(-45 18.142 7.514)"
  }), __jsx("path", {
    id: "prefix__Line_2",
    d: "M0 0h21.254",
    className: "prefix__cls-1",
    "data-name": "Line 2",
    transform: "rotate(45)"
  })));
}
export default Close;