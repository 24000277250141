import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var styles = css(["position:absolute;top:0;z-index:7;width:100%;background-color:var(--sc-alert--bg-color,", ");max-width:1600px;opacity:0.95;padding:var(--sc-spacing-7,43px) var(--sc-spacing-4,20px) var(--sc-spacing-4,20px);margin:0 auto;text-align:center;box-shadow:0 4px 20px 0 rgba(0,0,0,0.14);.close-button{fill:var(--sc-alert--close-btn-color,", ");width:18px;height:18px;position:absolute;top:var(--sc-spacing-4,15px);right:var(--sc-spacing-4,15px);background:none;border:none;padding:0;outline:none;}p{color:var(--sc-alert--para-color,", ");}.container{& > *{padding:0;margin:0;}}@media (min-width:", "){padding:var(--sc-spacing-7,43px) 0 var(--sc-spacing-4,20px);}@media (min-width:", "){padding:var(--sc-spacing-5,27px) 0;.close-button{top:50%;transform:translateY(-50%);}}"], function (props) {
  var _props$theme, _props$theme$alert;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$alert = _props$theme.alert) === null || _props$theme$alert === void 0 ? void 0 : _props$theme$alert.bgColor;
}, function (props) {
  var _props$theme2, _props$theme2$alert;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$alert = _props$theme2.alert) === null || _props$theme2$alert === void 0 ? void 0 : _props$theme2$alert.closeBtnColor;
}, function (props) {
  var _props$theme3, _props$theme3$alert;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$alert = _props$theme3.alert) === null || _props$theme3$alert === void 0 ? void 0 : _props$theme3$alert.paraColor;
}, breakPoints.tabletP, breakPoints.desktop);
export default styles;