import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import textures from '../../../themes/textures';
import { getBackgroundStyle, isBgColorStyleLight } from '../../../utils/utilityFunction';
var backgroundStyles = '';
var styles = css(["", ";width:100%;max-width:1600px;padding:var(--sc-spacing-3,10px) 0;margin:0 auto;text-align:center;.container{& > *{padding:0;margin:0;}ul,ol{li{display:block;p{display:list-item;list-style-position:inside;}}}h2,h3,h4,ul,ol,a,p,.heading_four{color:", ";}a{text-decoration:underline;}}@media (min-width:", "){padding:var(--sc-spacing-3,12px) 0;}"], function (props) {
  if (props.bannerData) {
    var _props$theme, _props$theme$pencilBa;
    var _props$bannerData = props.bannerData,
      backgroundImage = _props$bannerData.backgroundImage,
      overrideBackground = _props$bannerData.overrideBackground;
    var backgroundImageURLs = backgroundImage ? {
      desktopImage: backgroundImage.image && backgroundImage.image.url,
      mobileImage: backgroundImage.mobileImage && backgroundImage.mobileImage.url
    } : overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$pencilBa = _props$theme.pencilBanner) === null || _props$theme$pencilBa === void 0 ? void 0 : _props$theme$pencilBa.bgColor), props.theme.palette, textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$bannerData2, _props$bannerData3, _props$bannerData4;
  if ((props === null || props === void 0 ? void 0 : (_props$bannerData2 = props.bannerData) === null || _props$bannerData2 === void 0 ? void 0 : _props$bannerData2.fontColor) === null) {
    var _props$theme2, _props$theme2$pencilB, _props$theme3, _props$theme3$pencilB;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-pencil-banner--text-color-dark, ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$pencilB = _props$theme2.pencilBanner) === null || _props$theme2$pencilB === void 0 ? void 0 : _props$theme2$pencilB.textColorDark, ")") : "var(--sc-pencil-banner--text-color-light, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$pencilB = _props$theme3.pencilBanner) === null || _props$theme3$pencilB === void 0 ? void 0 : _props$theme3$pencilB.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$bannerData3 = props.bannerData) === null || _props$bannerData3 === void 0 ? void 0 : _props$bannerData3.fontColor) === fontColor.light) {
    var _props$theme4, _props$theme4$pencilB;
    return "var(--sc-pencil-banner--text-color-light, ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$pencilB = _props$theme4.pencilBanner) === null || _props$theme4$pencilB === void 0 ? void 0 : _props$theme4$pencilB.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$bannerData4 = props.bannerData) === null || _props$bannerData4 === void 0 ? void 0 : _props$bannerData4.fontColor) === fontColor.dark) {
    var _props$theme5, _props$theme5$pencilB;
    return "var(--sc-pencil-banner--text-color-dark, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$pencilB = _props$theme5.pencilBanner) === null || _props$theme5$pencilB === void 0 ? void 0 : _props$theme5$pencilB.textColorDark, ")");
  }
}, breakPoints.desktop);
export default styles;